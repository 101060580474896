/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Slideshow, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-ta6q1o --full pb--30 pt--50" name={"introduction"} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32129/4f8a58616465489d9e590b89c4f667fe_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32129/4f8a58616465489d9e590b89c4f667fe_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32129/4f8a58616465489d9e590b89c4f667fe_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32129/4f8a58616465489d9e590b89c4f667fe_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32129/4f8a58616465489d9e590b89c4f667fe_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32129/4f8a58616465489d9e590b89c4f667fe_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32129/4f8a58616465489d9e590b89c4f667fe_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/32129/4f8a58616465489d9e590b89c4f667fe_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--justify fs--185" content={"<span style=\"color: white\"><br><br>&nbsp; &nbsp; <br><br>&nbsp; &nbsp;Asia &amp;&nbsp; Henrik<br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--86" content={"<span style=\"color: white\">(and Harper)<br></span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--60 pt--80" name={"information"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--86 w--300 ls--0" style={{"maxWidth":880}} content={"<span style=\"color: var(--color-dominant)\">We're delighted to invite you to our wedding!</span>"}>
              </Title>

              <Text className="text-box lh--2 mt--25" style={{"maxWidth":880}} content={"After 5 years of dating and one illegitimate baby we decided to get married. We cannot imagine this day without our family and friends! As we both have a special connection with Spain, we are going to have our wedding in Andalucia. If you have ever been to this magical region, you probably quite understand our choice. If you have not, we are excited to introduce you to this marvellous place!<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"9kr3eed8xyl"}>
          
          <ColumnWrap className="column__flex --center el--2" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--72" content={"The <br>Wedding"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--center fs--26 pt--08" style={{"maxWidth":678}} content={"<span style=\"color: var(--black);\">Date:</span> 26/04/2024<br><span style=\"color: var(--black);\">Time:</span> 16:15 (for those who require, pick up from Nerja TBC)<br><span style=\"color: var(--black);\">Location:</span> Hotel Cortijo Bravo, Andalucia, Spain<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"yy36i3ami1l"} style={{"paddingTop":1,"paddingBottom":3}}>
        </Column>


        <Column className="css-rdr9j2 css-1pbuso2 --left pb--60 pt--60" name={"whi50kal8am"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/32129/2b35282b084f4c5cb0c8e1004ed45948_bri=85_fo__s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/32129/2b35282b084f4c5cb0c8e1004ed45948_bri=85_fo__s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/32129/2b35282b084f4c5cb0c8e1004ed45948_bri=85_fo__s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/32129/2b35282b084f4c5cb0c8e1004ed45948_bri=85_fo__s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/32129/2b35282b084f4c5cb0c8e1004ed45948_bri=85_fo__s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/32129/2b35282b084f4c5cb0c8e1004ed45948_bri=85_fo__s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/32129/2b35282b084f4c5cb0c8e1004ed45948_bri=85_fo__s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/32129/2b35282b084f4c5cb0c8e1004ed45948_bri=85_fo__s=3000x_.jpg);
    }
  
background-position: 55% 45%;
    `}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"<span style=\"color: var(--white);\">The day before and after...</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Text className="text-box pt--20" style={{"maxWidth":650}} content={"<span style=\"color: var(--white);\">We hope that our wedding in Spain will be a fantastic opportunity not only to celebrate our marriage but also to reconnect with our Friends &amp; Family. Therefore, please let us know in the form below if you would be interested in joining any activities on the day before/after our wedding!&nbsp;&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"v8rz8lpihkp"} style={{"marginTop":1,"paddingTop":1,"paddingBottom":0}}>
        </Column>


        <Slideshow name={"hbpk45m0lkf"} infinite={false} indicators={"none"}>
          
          <Column className="css-vqir31 --full pb--60 pt--60" fullscreen={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/32129/a1ca2cbe85654794b15911563532cfcb_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/32129/a1ca2cbe85654794b15911563532cfcb_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/32129/a1ca2cbe85654794b15911563532cfcb_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/32129/a1ca2cbe85654794b15911563532cfcb_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/32129/a1ca2cbe85654794b15911563532cfcb_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/32129/a1ca2cbe85654794b15911563532cfcb_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/32129/a1ca2cbe85654794b15911563532cfcb_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.35), rgba(0,0,0,0.35)), url(https://cdn.swbpg.com/t/32129/a1ca2cbe85654794b15911563532cfcb_s=3000x_.jpg);
    }
  
    `}>
            
            <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
              
              <ColumnWrapper >
                
                <Title className="title-box fs--102 title-box--invert" content={"Survey &amp; RSVP"}>
                </Title>

                <Text className="text-box text-box--invert" style={{"maxWidth":650}} content={"Please RSVP and fill in the below survey by latest 15th December<br>Thank you!&nbsp;<br>"}>
                </Text>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

        </Slideshow>


        <Column name={"gufcteh59mk"} style={{"paddingTop":0,"paddingBottom":4}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1" style={{"maxWidth":730}} action={"/contact"} fields={[{"name":"You & your partner's (if applicable) full name(s)","type":"text","required":true,"placeholder":"Enter your full name"},{"name":"Will you join us at our Wedding?","type":"text","required":true},{"name":"Will you join us with your kids? If so, please provide their names and age. ","type":"text","required":false,"placeholder":""},{"name":"Do you have any dietary requirements? ","type":"text"},{"name":"Would you be interested in joining for an informal activity on Thursday afternoon?","type":"text","required":false},{"name":"Will you be interested in joining for lunch on Saturday (day after wedding)?","type":"text","required":false},{"name":"Your Email Address","type":"text"},{"name":"Send","type":"submit","align":"center"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"photogallery"} layout={"l12"}>
          
          <ColumnWrap className="column__flex --center el--3 --full" columns={"3"} fullscreen={true}>
            
            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-lf00qu pt--80" name={"ghi8ixi1udu"} style={{"marginTop":0,"marginBottom":2,"paddingBottom":263}} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/32129/06599578b0554ac8b7cb986fa56a3f09_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/32129/06599578b0554ac8b7cb986fa56a3f09_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/32129/06599578b0554ac8b7cb986fa56a3f09_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/32129/06599578b0554ac8b7cb986fa56a3f09_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/32129/06599578b0554ac8b7cb986fa56a3f09_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/32129/06599578b0554ac8b7cb986fa56a3f09_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/32129/06599578b0554ac8b7cb986fa56a3f09_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/32129/06599578b0554ac8b7cb986fa56a3f09_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper style={{"paddingBottom":0}}>
              
              <Title className="title-box title-box--right" style={{"maxWidth":1000,"marginTop":0,"paddingTop":135}} content={"<span style=\"color: var(--white); font-weight: bold;\"><br>&nbsp; &nbsp; &nbsp; &nbsp;We hope to see You soon!</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"fymksy3njq9"} style={{"paddingTop":0,"paddingBottom":0}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"Contact us!"}>
              </Title>

              <Text className="text-box text-box--center" style={{"maxWidth":334}} content={"E-mail: Asiaandhenrik@gmail.com<br>Phone numbers:<br>Asia: +48722098737<br>Henrik: +48695503265<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}